{
    "about-us": "Über uns",
    "services": "Leistungen",
    "contact": "Kontakt",
    "services-sub-1": "ERP-Lösungen",
    "services-sub-2": "IT-Lösungen",
    "services-sub-3": "IT-Sicherheitskonzepte",
    "services-sub-4": "Virtualisierung",
    "hero-title":"NGSI ist Ihr professioneller Partner rund um Ihre IT.",
    "hero-content":"Unser Team aus erfahrenen und innovativen Köpfen optimiert Ihre gesamte IT-Landschaft. Wo auch immer der Schuh drückt, wir haben dank unserer 360°-Sicht stets die passende Lösung für Sie parat.",
    "hero":"serviceItems",
    "link-button":"Mehr erfahren",
    "middle-section-header":"Zeit, mit NGSI zu wachsen",
    "middle-section-title":"Dynamics 365 Business Central - eine der stärksten ERP-Lösungen auf dem Markt",
    "middle-section-content":"Die Lösung rationalisiert und vereint die wichtigsten Geschäftsprozesse. Wir bieten Ihnen eine Bewertung des Geschäftsbetriebs und der Strategien, Follow-up in allen Projektphasen, übernehmen die Verantwortung und garantieren eine reibungslose Projektleistung von der Geschäftsprozessanalyse bis zum Post-Live-Support.",
    "explore-title":"Möchten Sie mehr über uns und unsere Arbeit erfahren?",
    "explore-content":"Kontaktieren Sie uns und überzeugen Sie sich von unserem Service.",
    "explore-phone":"JETZT ERREICHEN!",
    "references":"Unsere Partner",
    "contact-form-title":"Kontaktformular",
    "contact-form-sub-title":"Fragen Sie uns alles und wir hören gerne von Ihnen",
    "contact-form-full-name":"Vollständiger Name",
    "contact-form-email-address":"E-Mail-Adresse",
    "contact-form-subject":"Betreff",
    "contact-form-message":"Nachricht",
    "contact-form-submit-button":"Kontakt aufnehmen",
    "contact-side-follow-us":"Folgen Sie uns",
    "contact-side-title":"Ultimative ERP-Lösung",
    "contact-side-desctription":"ERP-Entwicklung in Business Central bezieht sich auf die Anpassung und Erweiterung der Funktionalität von Microsoft Dynamics 365 Business Central, das eine All-in-One-Unternehmensmanagementlösung für kleine und mittelständische Unternehmen darstellt.",
    "footer-about-us":"In einer Zeit, in der die IT für den Betriebserfolg ausschlaggebender denn je ist, werden die Anforderungen – egal in welcher Branche – umso komplexer. Mit Hauptsitz in Weissach bei Stuttgart ist NGSI – NextGen Solution & Innovation Ihr Partner für Ihre gesamte IT.",
    "about-us-main-title":"Profitieren Sie von unserer jahrzehntelangen Expertise!",
    "about-us-main-card-first-title":"Wir schauen",
    "about-us-main-card-first-description":"und zwar ganz genau, wo überall es nicht so läuft, wie es sein soll. Nach Analyse der gegenwärtigen Systeme werden Schwachstellen erfasst und ein Status Quo erstellt. Ist die Erstkonzeption fertig, klopfen wir erneut an Ihrer Tür.",
    "about-us-main-card-second-title":"...erörtern",
    "about-us-main-card-second-description":"mit Ihnen, welche Lösungsansätze für Sie in Frage kommen. Entsprechend Ihren Wünschen schmieden wir gemeinsam den weiteren Fahrplan, sodass keine Details offen bleiben – natürlich gemäß Ihrem vorgegebenem Budget.",
    "about-us-main-card-third-title":"und legen los!",
    "about-us-main-card-third-description":"Wir realisieren die einzelnen Punkte wie vereinbart, halten regelmäßig Rücksprache mit Ihnen und betreuen Sie auch nach Abschluss des Projekts, damit Sie sich weiterhin über die steigende Produktivität und sinkenden IT-Kosten freuen!",
    "about-us-sub-main-title": "Entdecken Sie uns",
    "about-us-sub-first-title": "Mehr als nur IT-Lösungen",
    "about-us-sub-first-decription": "In einer Zeit, in der IT für den operativen Erfolg wichtiger ist als je zuvor, werden die Anforderungen unabhängig von der Branche immer komplexer.",
    "about-us-sub-first-decription-2": "Mit Hauptsitz in Weissach bei Stuttgart ist NGSI - NextGen Solution & Innovation Ihr Partner für alle Ihre IT-Belange. Wir begleiten Sie gerne von der Planung und Beschaffung über die Umsetzung Ihres Projekts bis hin zum Betrieb und natürlich darüber hinaus.",
    "about-us-sub-first-decription-3": "Neben Großkunden zählen auch kleine und mittelständische Unternehmen sowie Institutionen aus verschiedensten Branchen zu unseren Kunden. Dank fundierter Fachkenntnisse, Innovationsgeist und einem über die Jahre gewachsenen Netzwerk von Partnern und Dienstleistern können wir auf die vielfältigsten Wünsche unserer Kunden eingehen.",
    "about-us-sub-second-title": "Was uns auszeichnet",
    "about-us-sub-second-decription": "Unser Helpdesk zeichnet sich durch Flexibilität und zielgerichtete Lösungsansätze aus. Wir unterstützen Sie vor Ort, telefonisch, per E-Mail oder durch den Einsatz von Fernwartungssoftware, auch außerhalb der regulären Geschäftszeiten.",
    "about-us-sub-third-title": "Und zu guter Letzt",
    "about-us-sub-third-decription": "Geschäfte hin und her - wir sind alle Menschen. Und der Kunde als Mensch steht im Mittelpunkt unseres Handelns. NGSI steht für persönlichen und offenen Kontakt auf allen möglichen Kommunikationsebenen. Unsere Leidenschaft besteht darin, alle Geschäftspartner fair, transparent und mit Empathie zu behandeln.",
    "contact-down-email": "Email",
    "contact-down-office": "Büro",
    "contact-down-phone": "Telefon",
    "contact-down-email-des": "Unser freundliches Team ist hier, um zu helfen.",
    "contact-down-office-des": "Besuchen Sie uns in unserem Hauptquartier.",
    "contact-down-phone-des": "Montag bis Freitag von 8 bis 17 Uhr telefonisch erreichbar.",
    "modal-close":"schließen",
    "mobil-about-us-decription":"Erfahren Sie mehr über NGSI",
    "mobil-solutions-description":"Lösungen für die IT-Branche",
    "mobil-contact-decription":"Erfahren Sie mehr über NGSI",
    "cookie-title":"Cookie-Hinweis",
    "cookie-description":"Wir verwenden Cookies für einen bestmöglichen Service. Mit Nutzung dieser Website stimmen Sie der Verwendung von Cookies zu",
    "cookie-info-button":"Weitere Informationen",
    "cookie-accept-button":"Alles klar - Akzeptieren!",
    "mix-contact-title":"Kontaktieren Sie uns",
    "mix-contact-1":"Bitte gehen Sie ohne zu zögern zu unserer",
    "mix-contact-2":"Kontaktseite",
    "mix-contact-3":"um Ihre Fragen zu stellen oder Ihre Bedürfnisse mitzuteilen. Wir helfen Ihnen gerne weiter.",
    "mix-service-title": "Zögern Sie nicht, unsere Dienstleistungen auszuprobieren.",
    "mix-service-description": "Entdecken Sie unsere leistungsstarken Lösungen, die wir anbieten."


  }
  